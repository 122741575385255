/* @font-face {
	font-family: Avenir;
	src: url('./fonts/AvenirLTStd-Medium.eot');
	font-weight: normal;
	font-style: normal;
} */
body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Avenir', sans-serif;
  line-height: 1.5;
  min-height: 100vh;
  background-color: #E71823;
}

.App {
  text-align: center;
  display: grid;
  justify-content: center;
  align-self: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  scale: 0.95;
  border-radius: 20px;
  min-height: 100vh;
  padding: 1%;
  background-color: white;
  color: #333;
}

.inside-container {
  margin-top: 1vh;
}

.inside-container p {
  margin-top: 4vh;
  margin-bottom: 1vh;
}

.location-btn {
  width: 100%;
}

.home-buttom {
  display: flex;
  justify-content: center;
}

.buttom-continue {
  width: auto;
  height: auto;

}

.alert {
  width: 100%;
}

@media (max-height: 550px) {
  .inside-container {
    margin-top: 0vh;
    scale: .92;
  }

  .inside-container p {
    margin-top: 0vh;
    margin-bottom: 1vh;
  }

  .home-buttom {
    top: 0%;

  }
}

.table-scroll {
  display: flex;
  flex-direction: column;
  max-height: 47vh
}

.App-logo {
  width: 50%;
  padding: 20px 20px 5px 20px;
}

.home-logo {
  height: 50px;
  padding: 6%;
}

.home-logo-alvi {
  width: 100px;
  height: 70px;
  padding: 2%;

}

.home-card {
  border-radius: 20px;

}

.logocab {
  width: 60%;
  padding: 20px 20px 5px 20px;
}

.container-home {
  width: 100%;
  margin: auto;
  padding: 10%;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: #333;
}

.impus {
  font-size: 1rem;
}

@media (max-height: 700px) {
  body {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: 'Avenir', sans-serif;
    line-height: 1.0;
  }
}

@media (max-width: 500px) {
  .App-logo {
    padding: 5%;
  }

  .logocab {
    padding: 10% 20px 5px 20px;
  }
}

.btn-ini {
  width: 80%;
  height: 8vh;
  text-decoration: none !important;
  font-size: 1.0rem !important;
  font-weight: 600 !important;
  text-decoration: none;
}

.sec1 {
  display: inline;
}

.sec2 {
  display: inline;
}

.sec3 {
  display: inline;
}

.fcontrol {
  text-align: left;
  font-size: 12px;
}

/* icono animado scroll */
@keyframes scroll {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  75% {
    transform: translateY(.75em);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

.scroll-icon {
  display: block;
  position: relative;
  height: 2em;
  width: 1em;
  border: .1em solid #ffffff;
  border-radius: 1em;
}

.scroll-icon__dot {
  display: block;
  position: absolute;
  left: 50%;
  background: #ffffff;
  height: .5em;
  width: .5em;
  top: .6em;
  margin-left: -.25em;
  border-radius: 50%;
  transform-origin: top center;
  backface-visibility: hidden;
  animation: scroll 2s ease-out infinite;
}